<template>
	<div>
		<b-row>
			<b-col cols="2" class="text-center" offset="2">
				<p class="fw-700 fs-14">
					{{ FormMSG(56, 'Gate keeper') }}
				</p>
			</b-col>
			<b-col cols="2" class="text-center">
				<p class="fw-700 fs-14">
					{{ FormMSG(61, 'Production accountant') }}
				</p>
			</b-col>
			<b-col cols="2" class="text-center">
				<p class="fw-700 fs-14">
					{{ FormMSG(58, 'UPM') }}
				</p>
			</b-col>
			<b-col cols="2" class="text-center">
				<p class="fw-700 fs-14">
					{{ FormMSG(59, 'Line producer') }}
				</p>
			</b-col>
			<b-col cols="2" class="text-center">
				<p class="fw-700 fs-14">
					{{ FormMSG(60, 'Studio') }}
				</p>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="2">
				<p class="fw-700 fs-14">
					{{ FormMSG(62, 'Step 1') }}
				</p>
			</b-col>
			<b-col class="text-center" cols="2">
				<b-form-checkbox
					v-model="step1.gateKeeper"
					:value="true"
					:unchecked-value="false"
					size="lg"
					:disabled="isDisabled || (disableOtherStep1 && !step1.gateKeeper)"
					@change="handleCheckRoleByStep($event, 1, 'gateKeeper')"
				/>
			</b-col>
			<b-col class="text-center" cols="2">
				<b-form-checkbox
					v-model="step1.productionAccountant"
					:value="true"
					:unchecked-value="false"
					size="lg"
					:disabled="isDisabled || (disableOtherStep1 && !step1.productionAccountant)"
					@change="handleCheckRoleByStep($event, 1, 'productionAccountant')"
				/>
			</b-col>
			<b-col class="text-center" cols="2">
				<b-form-checkbox
					v-model="step1.upm"
					:value="true"
					:unchecked-value="false"
					size="lg"
					:disabled="isDisabled || (disableOtherStep1 && !step1.upm)"
					@change="handleCheckRoleByStep($event, 1, 'upm')"
				/>
			</b-col>
			<b-col class="text-center" cols="2">
				<b-form-checkbox
					v-model="step1.lineProducer"
					:value="true"
					:unchecked-value="false"
					size="lg"
					:disabled="isDisabled || (disableOtherStep1 && !step1.lineProducer)"
					@change="handleCheckRoleByStep($event, 1, 'lineProducer')"
				/>
			</b-col>
			<b-col class="text-center" cols="2">
				<b-form-checkbox
					v-model="step1.studio"
					:value="true"
					:unchecked-value="false"
					size="lg"
					:disabled="isDisabled || (disableOtherStep1 && !step1.studio)"
					@change="handleCheckRoleByStep($event, 1, 'studio')"
				/>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="2">
				<p class="fw-700 fs-14">
					{{ FormMSG(63, 'Step 2') }}
				</p>
			</b-col>
			<b-col class="text-center" cols="2">
				<b-form-checkbox
					v-model="step2.gateKeeper"
					:value="true"
					:unchecked-value="false"
					size="lg"
					:disabled="disabledStep2 || (disableOtherStep2 && !step2.gateKeeper) || step1.gateKeeper"
					@change="handleCheckRoleByStep($event, 2, 'gateKeeper')"
				/>
			</b-col>
			<b-col class="text-center" cols="2">
				<b-form-checkbox
					v-model="step2.productionAccountant"
					:value="true"
					:unchecked-value="false"
					size="lg"
					:disabled="disabledStep2 || (disableOtherStep2 && !step2.productionAccountant) || step1.productionAccountant"
					@change="handleCheckRoleByStep($event, 2, 'productionAccountant')"
				/>
			</b-col>
			<b-col class="text-center" cols="2">
				<b-form-checkbox
					v-model="step2.upm"
					:value="true"
					:unchecked-value="false"
					size="lg"
					:disabled="disabledStep2 || (disableOtherStep2 && !step2.upm) || step1.upm"
					@change="handleCheckRoleByStep($event, 2, 'upm')"
				/>
			</b-col>
			<b-col class="text-center" cols="2">
				<b-form-checkbox
					v-model="step2.lineProducer"
					:value="true"
					:unchecked-value="false"
					size="lg"
					:disabled="disabledStep2 || (disableOtherStep2 && !step2.lineProducer) || step1.lineProducer"
					@change="handleCheckRoleByStep($event, 2, 'lineProducer')"
				/>
			</b-col>
			<b-col class="text-center" cols="2">
				<b-form-checkbox
					v-model="step2.studio"
					:value="true"
					:unchecked-value="false"
					size="lg"
					:disabled="disabledStep2 || (disableOtherStep2 && !step2.studio) || step1.studio"
					@change="handleCheckRoleByStep($event, 2, 'studio')"
				/>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="2">
				<p class="fw-700 fs-14">
					{{ FormMSG(64, 'Step 3') }}
				</p>
			</b-col>
			<b-col class="text-center" cols="2">
				<b-form-checkbox
					v-model="step3.gateKeeper"
					:value="true"
					:unchecked-value="false"
					size="lg"
					:disabled="disabledStep3 || (disableOtherStep3 && !step3.gateKeeper) || step1.gateKeeper || step2.gateKeeper"
					@change="handleCheckRoleByStep($event, 3, 'gateKeeper')"
				/>
			</b-col>
			<b-col class="text-center" cols="2">
				<b-form-checkbox
					v-model="step3.productionAccountant"
					:value="true"
					:unchecked-value="false"
					size="lg"
					:disabled="disabledStep3 || (disableOtherStep3 && !step3.productionAccountant) || step1.productionAccountant || step2.productionAccountant"
					@change="handleCheckRoleByStep($event, 3, 'productionAccountant')"
				/>
			</b-col>
			<b-col class="text-center" cols="2">
				<b-form-checkbox
					v-model="step3.upm"
					:value="true"
					:unchecked-value="false"
					size="lg"
					:disabled="disabledStep3 || (disableOtherStep3 && !step3.upm) || step1.upm || step2.upm"
					@change="handleCheckRoleByStep($event, 3, 'upm')"
				/>
			</b-col>
			<b-col class="text-center" cols="2">
				<b-form-checkbox
					v-model="step3.lineProducer"
					:value="true"
					:unchecked-value="false"
					size="lg"
					:disabled="disabledStep3 || (disableOtherStep3 && !step3.lineProducer) || step1.lineProducer || step2.lineProducer"
					@change="handleCheckRoleByStep($event, 3, 'lineProducer')"
				/>
			</b-col>
			<b-col class="text-center" cols="2">
				<b-form-checkbox
					v-model="step3.studio"
					:value="true"
					:unchecked-value="false"
					size="lg"
					:disabled="disabledStep3 || (disableOtherStep3 && !step3.studio) || step1.studio || step2.studio"
					@change="handleCheckRoleByStep($event, 3, 'studio')"
				/>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="2">
				<p class="fw-700 fs-16">
					{{ FormMSG(45, 'Signature') }}
				</p>
			</b-col>
			<b-col cols="2" class="text-center">
				<b-form-checkbox
					v-model="toSignGateKeeper"
					:value="true"
					:unchecked-value="false"
					size="lg"
					:disabled="isDisabled || disabledToSignGateKeeper"
					@change="handleCheckToSign($event, 'gateKeeper')"
				/>
			</b-col>
			<b-col cols="2" class="text-center">
				<b-form-checkbox
					v-model="toSignProdAcc"
					:value="true"
					:unchecked-value="false"
					size="lg"
					:disabled="isDisabled || disabledToSignProductionAccountant"
					@change="handleCheckToSign($event, 'productionAccountant')"
				/>
			</b-col>
			<b-col cols="2" class="text-center">
				<b-form-checkbox
					v-model="toSignUPM"
					:value="true"
					:unchecked-value="false"
					size="lg"
					:disabled="isDisabled || disabledToSignUPM"
					@change="handleCheckToSign($event, 'upm')"
				/>
			</b-col>
			<b-col cols="2" class="text-center">
				<b-form-checkbox
					v-model="toSignLineProd"
					:value="true"
					:unchecked-value="false"
					size="lg"
					:disabled="isDisabled || disabledToSignLineProducer"
					@change="handleCheckToSign($event, 'lineProducer')"
				/>
			</b-col>
			<b-col cols="2" class="text-center">
				<b-form-checkbox
					v-model="toSignStudio"
					:value="true"
					:unchecked-value="false"
					size="lg"
					:disabled="isDisabled || disabledToSignStudio"
					@change="handleCheckToSign($event, 'studio')"
				/>
			</b-col>
		</b-row>
		<b-row class="mt-2">
			<b-col cols="2" class="text-center" offset="2">
				<v-select
					v-model="selectedGateKeeper"
					@input="handleChange($event, DOCUMENT_VALIDATION_LEVEL.gateKeeper)"
					v-if="toSignGateKeeper"
					:disabled="isDisabled"
					:options="gateKeeperUserList"
					label="fullName"
					:reduce="(option) => option.id"
					placeholder="Select a user"
				/>
			</b-col>
			<b-col cols="2" class="text-center">
				<v-select
					@input="handleChange($event, DOCUMENT_VALIDATION_LEVEL.productionAccountant)"
					v-model="selectedProductionAccountant"
					:options="productionAccountantUserList"
					label="fullName"
					:disabled="isDisabled"
					v-if="toSignProdAcc"
					placeholder="Select a user"
					:reduce="(option) => option.id"
				>
				</v-select>
			</b-col>
			<b-col cols="2" class="text-center">
				<v-select
					@input="handleChange($event, DOCUMENT_VALIDATION_LEVEL.upm)"
					:reduce="(option) => option.id"
					v-model="selectedUpm"
					:disabled="isDisabled"
					:options="upmUserList"
					label="fullName"
					v-if="toSignUPM"
					placeholder="Select a user"
				/>
			</b-col>
			<b-col cols="2" class="text-center">
				<v-select
					@input="handleChange($event, DOCUMENT_VALIDATION_LEVEL.lineProducer)"
					v-model="selectedLineProducer"
					:options="lineProducerUserList"
					label="fullName"
					:disabled="isDisabled"
					:reduce="(option) => option.id"
					v-if="toSignLineProd"
					placeholder="Select a user"
				/>
			</b-col>
			<b-col cols="2" class="text-center">
				<v-select
					@input="handleChange($event, DOCUMENT_VALIDATION_LEVEL.studio)"
					v-model="selectedStudio"
					:options="studioUserList"
					label="fullName"
					:disabled="isDisabled"
					:reduce="(option) => option.id"
					v-if="toSignStudio"
					placeholder="Select a user"
				/>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import _ from 'lodash';
import { getSignUsers } from '@/cruds/document.crud';
import { DOCUMENT_VALIDATION_STEP, DOCUMENT_VALIDATION_LEVEL } from '@/shared/constants';

export default {
	name: 'ChoiceStepDocument',

	mixins: [languageMessages],

	props: {
		isDisabled: { type: Boolean, default: false },
		document: { type: Object, required: true }
	},
	data() {
		return {
			step1: {
				gateKeeper: false,
				productionAccountant: false,
				upm: false,
				lineProducer: false,
				studio: false,
				userId: 0
			},
			step2: {
				gateKeeper: false,
				productionAccountant: false,
				upm: false,
				lineProducer: false,
				studio: false,
				userId: 0
			},
			step3: {
				gateKeeper: false,
				productionAccountant: false,
				upm: false,
				lineProducer: false,
				studio: false,
				userId: 0
			},
			selectedGateKeeper: null,
			selectedProductionAccountant: null,
			selectedUpm: null,
			selectedLineProducer: null,
			selectedStudio: null,
			toSignGateKeeper: false,
			firstCheckToSignGateKeeper: true,
			toSignProdAcc: false,
			firstCheckToSignProdAcc: true,
			toSignUPM: false,
			firstCheckToSignUPM: true,
			toSignLineProd: false,
			firstCheckToSignLineProd: true,
			toSignStudio: false,
			firstCheckToSignStudio: true,
			allUserList: null,
			gateKeeperUserList: [],
			productionAccountantUserList: [],
			upmUserList: [],
			lineProducerUserList: [],
			studioUserList: [],
			roleByStepToSend: [],
			roleByStepToSendOriginal: [],
			DOCUMENT_VALIDATION_LEVEL: DOCUMENT_VALIDATION_LEVEL
		};
	},
	async created() {
		await this.getUserList();
	},
	computed: {
		disableOtherStep1() {
			return this.step1.gateKeeper || this.step1.productionAccountant || this.step1.upm || this.step1.lineProducer || this.step1.studio;
		},
		disableOtherStep2() {
			return this.step2.gateKeeper || this.step2.productionAccountant || this.step2.upm || this.step2.lineProducer || this.step2.studio;
		},
		disableOtherStep3() {
			return this.step3.gateKeeper || this.step3.productionAccountant || this.step3.upm || this.step3.lineProducer || this.step3.studio;
		},
		disabledStep2() {
			if (!this.isDisabled) {
				if (this.step1.gateKeeper || this.step1.productionAccountant || this.step1.upm || this.step1.lineProducer || this.step1.studio) {
					return false;
				} else {
					this.deleteRoleSelectedFromStep(2);
					this.deleteRoleSelectedFromStep(3);
				}
			}

			return true;
		},
		disabledStep3() {
			if (!this.isDisabled) {
				if (this.step2.gateKeeper || this.step2.productionAccountant || this.step2.upm || this.step2.lineProducer || this.step2.studio) {
					return false;
				} else {
					this.deleteRoleSelectedFromStep(3);
				}
			}

			return true;
		},
		disabledToSignGateKeeper() {
			return !this.step1.gateKeeper && !this.step2.gateKeeper && !this.step3.gateKeeper;
			// return this.checkDisabledToSign('gateKeeper');
		},
		disabledToSignProductionAccountant() {
			return !this.step1.productionAccountant && !this.step2.productionAccountant && !this.step3.productionAccountant;
			// return this.checkDisabledToSign('productionAccountant');
		},
		disabledToSignUPM() {
			return !this.step1.upm && !this.step2.upm && !this.step3.upm;
			// return this.checkDisabledToSign('upm');
		},
		disabledToSignLineProducer() {
			return !this.step1.lineProducer && !this.step2.lineProducer && !this.step3.lineProducer;
			// return this.checkDisabledToSign('lineProducer');
		},
		disabledToSignStudio() {
			return !this.step1.studio && !this.step2.studio && !this.step3.studio;
			// return this.checkDisabledToSign('studio');
		}
	},
	watch: {
		document: {
			async handler(newVal) {
				if (newVal.docValConfigStep1 && newVal.docValConfigStep2 && newVal.docValConfigStep3) {
					this.roleByStepToSendOriginal = [];
					this.roleByStepToSend = [];
					await this.getUserList();
					await this.initRoleByStep(newVal);

					// this.manageFirstCheckToSign('gateKeeper', true);
					// this.manageFirstCheckToSign('productionAccountant', true);
					// this.manageFirstCheckToSign('upm', true);
					// this.manageFirstCheckToSign('lineProducer', true);
					// this.manageFirstCheckToSign('studio', true);
				}
			},
			immediate: true,
			deep: true
		}
	},
	methods: {
		filterUserOfOtherRole(initialRole, userId) {
			if (initialRole === 5) {
				this.productionAccountantUserList = this.allUserList.productionAccountants
					.filter((user) => user.id != userId)
					.map((user) => {
						let usrData = {
							id: +user.id,
							fullName: user.name + ' ' + user.firstName
						};
						return usrData;
					});
				this.upmUserList = this.allUserList.upm
					.filter((user) => user.id != userId)
					.map((user) => {
						let usrData = {
							id: +user.id,
							fullName: user.name + ' ' + user.firstName
						};
						return usrData;
					});
				this.lineProducerUserList = this.allUserList.lineProducers
					.filter((user) => user.id != userId)
					.map((user) => {
						let usrData = {
							id: +user.id,
							fullName: user.name + ' ' + user.firstName
						};
						return usrData;
					});
				this.studioUserList = this.allUserList.studios
					.filter((user) => user.id != userId)
					.map((user) => {
						let usrData = {
							id: +user.id,
							fullName: user.name + ' ' + user.firstName
						};
						return usrData;
					});
			} else if (initialRole === 6) {
				this.gateKeeperUserList = this.allUserList.gateKeepers
					.filter((user) => user.id != userId)
					.map((user) => {
						let usrData = {
							id: +user.id,
							fullName: user.name + ' ' + user.firstName
						};
						return usrData;
					});
				this.upmUserList = this.allUserList.upm
					.filter((user) => user.id != userId)
					.map((user) => {
						let usrData = {
							id: +user.id,
							fullName: user.name + ' ' + user.firstName
						};
						return usrData;
					});
				this.lineProducerUserList = this.allUserList.lineProducers
					.filter((user) => user.id != userId)
					.map((user) => {
						let usrData = {
							id: +user.id,
							fullName: user.name + ' ' + user.firstName
						};
						return usrData;
					});
				this.studioUserList = this.allUserList.studios
					.filter((user) => user.id != userId)
					.map((user) => {
						let usrData = {
							id: +user.id,
							fullName: user.name + ' ' + user.firstName
						};
						return usrData;
					});
			} else if (initialRole === 7) {
				this.productionAccountantUserList = this.allUserList.productionAccountants
					.filter((user) => user.id != userId)
					.map((user) => {
						let usrData = {
							id: +user.id,
							fullName: user.name + ' ' + user.firstName
						};
						return usrData;
					});
				this.gateKeeperUserList = this.allUserList.gateKeepers
					.filter((user) => user.id != userId)
					.map((user) => {
						let usrData = {
							id: +user.id,
							fullName: user.name + ' ' + user.firstName
						};
						return usrData;
					});
				this.lineProducerUserList = this.allUserList.lineProducers
					.filter((user) => user.id != userId)
					.map((user) => {
						let usrData = {
							id: +user.id,
							fullName: user.name + ' ' + user.firstName
						};
						return usrData;
					});
				this.studioUserList = this.allUserList.studios
					.filter((user) => user.id != userId)
					.map((user) => {
						let usrData = {
							id: +user.id,
							fullName: user.name + ' ' + user.firstName
						};
						return usrData;
					});
			} else if (initialRole === 8) {
				this.productionAccountantUserList = this.allUserList.productionAccountants
					.filter((user) => user.id != userId)
					.map((user) => {
						let usrData = {
							id: +user.id,
							fullName: user.name + ' ' + user.firstName
						};
						return usrData;
					});
				this.upmUserList = this.allUserList.upm
					.filter((user) => user.id != userId)
					.map((user) => {
						let usrData = {
							id: +user.id,
							fullName: user.name + ' ' + user.firstName
						};
						return usrData;
					});
				this.gateKeeperUserList = this.allUserList.gateKeepers
					.filter((user) => user.id != userId)
					.map((user) => {
						let usrData = {
							id: +user.id,
							fullName: user.name + ' ' + user.firstName
						};
						return usrData;
					});
				this.studioUserList = this.allUserList.studios
					.filter((user) => user.id != userId)
					.map((user) => {
						let usrData = {
							id: +user.id,
							fullName: user.name + ' ' + user.firstName
						};
						return usrData;
					});
			} else if (initialRole === 9) {
				this.productionAccountantUserList = this.allUserList.productionAccountants
					.filter((user) => user.id != userId)
					.map((user) => {
						let usrData = {
							id: +user.id,
							fullName: user.name + ' ' + user.firstName
						};
						return usrData;
					});
				this.upmUserList = this.allUserList.upm
					.filter((user) => user.id != userId)
					.map((user) => {
						let usrData = {
							id: +user.id,
							fullName: user.name + ' ' + user.firstName
						};
						return usrData;
					});
				this.lineProducerUserList = this.allUserList.lineProducers
					.filter((user) => user.id != userId)
					.map((user) => {
						let usrData = {
							id: +user.id,
							fullName: user.name + ' ' + user.firstName
						};
						return usrData;
					});
				this.gateKeeperUserList = this.allUserList.gateKeepers
					.filter((user) => user.id != userId)
					.map((user) => {
						let usrData = {
							id: +user.id,
							fullName: user.name + ' ' + user.firstName
						};
						return usrData;
					});
			}
		},
		handleChange(payload, field) {
			let roleByStepToSend = this.roleByStepToSend;

			let userId = 0;

			if (field === DOCUMENT_VALIDATION_LEVEL.gateKeeper) {
				userId = +this.selectedGateKeeper;
				this.filterUserOfOtherRole(DOCUMENT_VALIDATION_LEVEL.gateKeeper, this.selectedGateKeeper);
			} else if (field === DOCUMENT_VALIDATION_LEVEL.productionAccountant) {
				userId = +this.selectedProductionAccountant;
				this.filterUserOfOtherRole(DOCUMENT_VALIDATION_LEVEL.productionAccountant, this.selectedProductionAccountant);
			} else if (field === DOCUMENT_VALIDATION_LEVEL.upm) {
				userId = +this.selectedUpm;
				this.filterUserOfOtherRole(DOCUMENT_VALIDATION_LEVEL.upm, this.selectedUpm);
			} else if (field === DOCUMENT_VALIDATION_LEVEL.lineProducer) {
				userId = +this.selectedLineProducer;
				this.filterUserOfOtherRole(DOCUMENT_VALIDATION_LEVEL.lineProducer, this.selectedLineProducer);
			} else if (field === DOCUMENT_VALIDATION_LEVEL.studio) {
				userId = +this.selectedStudio;
				this.filterUserOfOtherRole(DOCUMENT_VALIDATION_LEVEL.studio, this.selectedStudio);
			}

			if (roleByStepToSend.length > 0) {
				for (let i = 0; i < roleByStepToSend.length; i++) {
					const element = roleByStepToSend[i];
					if (element.validationLevel === field) {
						roleByStepToSend[i].userId = userId;
					}
				}
			}

			if (this.roleByStepToSendOriginal.length > 0) {
				for (let i = 0; i < this.roleByStepToSendOriginal.length; i++) {
					const element = this.roleByStepToSendOriginal[i];

					if (element.validationLevel === field) {
						const findIndexFromRoleToSend = _.findIndex(roleByStepToSend, (rBSTS) => {
							return element.step === rBSTS.step && element.validationLevel === rBSTS.validationLevel;
						});

						if (findIndexFromRoleToSend === -1) {
							roleByStepToSend.push({
								docId: element.docId,
								step: element.step,
								validationLevel: element.validationLevel,
								action: 0,
								toSign: true,
								userId: userId
							});
						}
					}
				}
			}
			this.roleByStepToSend = roleByStepToSend;

			this.$emit('choice-step-document:change', roleByStepToSend);
		},
		async getUserList() {
			await getSignUsers().then((users) => {
				this.allUserList = users;
				this.gateKeeperUserList = users.gateKeepers.map((user) => {
					let usrData = {
						id: +user.id,
						fullName: user.name + ' ' + user.firstName
					};
					return usrData;
				});
				this.productionAccountantUserList = users.productionAccountants.map((user) => {
					let usrData = {
						id: +user.id,
						fullName: user.name + ' ' + user.firstName
					};
					return usrData;
				});
				this.upmUserList = users.upm.map((user) => {
					let usrData = {
						id: +user.id,
						fullName: user.name + ' ' + user.firstName
					};
					return usrData;
				});
				this.lineProducerUserList = users.lineProducers.map((user) => {
					let usrData = {
						id: +user.id,
						fullName: user.name + ' ' + user.firstName
					};
					return usrData;
				});
				this.studioUserList = users.studios.map((user) => {
					let usrData = {
						id: +user.id,
						fullName: user.name + ' ' + user.firstName
					};
					return usrData;
				});
			});
		},
		handleCheckRoleByStep(value, step, field) {
			let roleByStepToSend = this.roleByStepToSend;
			this.manageFirstCheckToSign(field, false, step);
			let dataToSend = {
				docId: +this.document.id,
				step,
				validationLevel: DOCUMENT_VALIDATION_LEVEL[field],
				action: 0,
				toSign: this.getFieldToSign(field),
				userId: 0
			};

			const findIndex = _.findIndex(roleByStepToSend, (rBSTD) => {
				return rBSTD.step === dataToSend.step && rBSTD.validationLevel === dataToSend.validationLevel;
			});

			if (findIndex > -1) {
				roleByStepToSend.splice(findIndex, 1);
			}

			if (value === true) {
				const findIndexFromOriginal = this.roleByStepToSendOriginal.findIndex(
					(rBSTSO) => rBSTSO.step === dataToSend.step && rBSTSO.validationLevel === dataToSend.validationLevel
				);
				if (findIndexFromOriginal === -1) {
					roleByStepToSend.push(dataToSend);
				}
			} else {
				const findIndexFromOriginal = _.findIndex(this.roleByStepToSendOriginal, (rBSTSO) => {
					return rBSTSO.step === dataToSend.step && rBSTSO.validationLevel === dataToSend.validationLevel;
				});

				if (findIndexFromOriginal > -1) {
					dataToSend.action = 1;
					roleByStepToSend.push(dataToSend);
				}
				this.revertUserSelection(value, field);
			}

			this.roleByStepToSend = roleByStepToSend;

			this.$emit('choice-step-document:change', roleByStepToSend);
		},
		getFieldToSign(field) {
			switch (field) {
				case 'gateKeeper':
					return this.toSignGateKeeper;
				case 'productionAccountant':
					return this.toSignProdAcc;
				case 'upm':
					return this.toSignUPM;
				case 'lineProducer':
					return this.toSignLineProd;
				case 'studio':
					return this.firstCheckToSignStudio;
			}
		},
		revertUserSelection(value, field) {
			if (field === 'gateKeeper') {
				this.selectedGateKeeper = null;
				this.filterUserOfOtherRole(DOCUMENT_VALIDATION_LEVEL.gateKeeper, this.selectedGateKeeper);
			} else if (field === 'productionAccountant') {
				this.selectedProductionAccountant = null;
				this.filterUserOfOtherRole(DOCUMENT_VALIDATION_LEVEL.productionAccountant, this.selectedProductionAccountant);
			} else if (field === 'upm') {
				this.selectedUpm = null;
				this.filterUserOfOtherRole(DOCUMENT_VALIDATION_LEVEL.upm, this.selectedUpm);
			} else if (field === 'lineProducer') {
				this.selectedLineProducer = null;
				this.filterUserOfOtherRole(DOCUMENT_VALIDATION_LEVEL.lineProducer, this.selectedLineProducer);
			} else if (field === 'studio') {
				this.selectedStudio = null;
				this.filterUserOfOtherRole(DOCUMENT_VALIDATION_LEVEL.studio, this.selectedStudio);
			}
		},
		handleCheckToSign(value, field) {
			const validationOption = DOCUMENT_VALIDATION_LEVEL[field];
			let roleByStepToSend = this.roleByStepToSend;
			if (!value) {
				this.revertUserSelection(value, field);
			}
			if (roleByStepToSend.length > 0) {
				for (let i = 0; i < roleByStepToSend.length; i++) {
					const element = roleByStepToSend[i];
					if (element.validationLevel === validationOption) {
						roleByStepToSend[i].toSign = value;
					}
				}
			}

			if (this.roleByStepToSendOriginal.length > 0) {
				for (let i = 0; i < this.roleByStepToSendOriginal.length; i++) {
					const element = this.roleByStepToSendOriginal[i];

					if (element.validationLevel === validationOption) {
						const findIndexFromRoleToSend = _.findIndex(roleByStepToSend, (rBSTS) => {
							return element.step === rBSTS.step && element.validationLevel === rBSTS.validationLevel;
						});

						if (findIndexFromRoleToSend === -1) {
							roleByStepToSend.push({
								docId: element.docId,
								step: element.step,
								validationLevel: element.validationLevel,
								action: 0,
								toSign: value,
								userId: element.userId
							});
						}
					}
				}
			}

			this.roleByStepToSend = roleByStepToSend;

			this.$emit('choice-step-document:change', roleByStepToSend);
		},
		/**
		 * foWatch: on utilise le params forWatch quand on est dans le methode watch de vue
		 * field: le role à checker
		 */
		manageFirstCheckToSign(field, forWatch = false, step) {
			const validationOption = DOCUMENT_VALIDATION_LEVEL[field];

			if (step === DOCUMENT_VALIDATION_STEP.step1) {
				this.toSignGateKeeper = this.step1.gateKeeper || this.step2.gateKeeper || this.step3.gateKeeper;
				this.toSignProdAcc = this.step1.productionAccountant || this.step2.productionAccountant || this.step3.productionAccountant;
				this.toSignUPM = this.step1.upm || this.step2.upm || this.step3.upm;
				this.toSignLineProd = this.step1.lineProducer || this.step2.lineProducer || this.step3.lineProducer;
				this.toSignStudio = this.step1.studio || this.step2.studio || this.step3.studio;
			} else if (step === DOCUMENT_VALIDATION_STEP.step2) {
				!this.toSignGateKeeper
					? (this.toSignGateKeeper = this.toSignGateKeeper)
					: (this.toSignGateKeeper = this.step1.gateKeeper ? this.step1.gateKeeper : this.step2.gateKeeper || this.step3.gateKeeper);
				!this.toSignProdAcc
					? (this.toSignProdAcc = this.toSignProdAcc)
					: (this.toSignProdAcc = this.step1.productionAccountant
							? this.step1.productionAccountant
							: this.step2.productionAccountant || this.step3.productionAccountant);
				!this.toSignUPM ? (this.toSignUPM = this.toSignUPM) : (this.toSignUPM = this.step1.upm ? this.step1.upm : this.step2.upm || this.step3.upm);
				!this.toSignLineProd
					? (this.toSignLineProd = this.toSignLineProd)
					: (this.toSignLineProd = this.step1.lineProducer ? this.step1.lineProducer : this.step2.lineProducer || this.step3.lineProducer);
				!this.toSignStudio
					? (this.toSignStudio = this.toSignStudio)
					: (this.toSignStudio = this.step1.studio ? this.step1.studio : this.step2.studio || this.step3.studio);
			}

			switch (field) {
				case 'gateKeeper':
					if (forWatch) {
						const findIndexGateKeeper = _.findIndex(this.roleByStepToSendOriginal, (rBSTSO) => {
							return (rBSTSO.validationLevel = validationOption);
						});

						if (findIndexGateKeeper > -1) {
							this.firstCheckToSignGateKeeper = false;
						}
					} else {
						this.firstCheckToSignGateKeeper = false;
						this.toSignGateKeeper = this.step1.gateKeeper || this.step2.gateKeeper || this.step3.gateKeeper;
					}

					break;
				case 'productionAccountant':
					if (forWatch) {
						const findIndexProdAcc = _.findIndex(this.roleByStepToSendOriginal, (rBSTSO) => {
							return (rBSTSO.validationLevel = validationOption);
						});

						if (findIndexProdAcc > -1) {
							this.firstCheckToSignProdAcc = false;
						}
					} else {
						this.firstCheckToSignProdAcc = false;
						this.toSignProdAcc = this.step1.productionAccountant || this.step2.productionAccountant || this.step3.productionAccountant;
					}

					break;
				case 'upm':
					if (forWatch) {
						const findIndexUPM = _.findIndex(this.roleByStepToSendOriginal, (rBSTSO) => {
							return (rBSTSO.validationLevel = validationOption);
						});

						if (findIndexUPM > -1) {
							this.firstCheckToSignUPM = false;
						}
					} else {
						this.firstCheckToSignUPM = false;
						this.toSignUPM = this.step1.upm || this.step2.upm || this.step3.upm;
					}

					break;
				case 'lineProducer':
					if (forWatch) {
						const findIndexLineProd = _.findIndex(this.roleByStepToSendOriginal, (rBSTSO) => {
							return (rBSTSO.validationLevel = validationOption);
						});

						if (findIndexLineProd > -1) {
							this.firstCheckToSignLineProd = false;
						}
					} else {
						this.firstCheckToSignLineProd = false;
						this.toSignLineProd = this.step1.lineProducer || this.step2.lineProducer || this.step3.lineProducer;
					}

					break;
				case 'studio':
					if (forWatch) {
						const findIndexStudio = _.findIndex(this.roleByStepToSendOriginal, (rBSTSO) => {
							return (rBSTSO.validationLevel = validationOption);
						});

						if (findIndexStudio > -1) {
							this.firstCheckToSignStudio = false;
						}
					} else {
						this.firstCheckToSignStudio = false;
						this.toSignStudio = this.step1.studio || this.step2.studio || this.step3.studio;
					}

					break;
			}
		},
		async initRoleByStep(doc) {
			for (let i = 1; i < 4; i++) {
				const docValConfigStep = doc[`docValConfigStep${i}`];
				docValConfigStep.forEach((dVCS) => {
					if (dVCS.validationLevel === DOCUMENT_VALIDATION_LEVEL.gateKeeper) {
						this[`step${i}`].gateKeeper = true;
						this.roleByStepToSendOriginal.push({
							docId: +this.document.id,
							step: i,
							validationLevel: DOCUMENT_VALIDATION_LEVEL.gateKeeper,
							toSign: dVCS.toSign,
							userId: dVCS.userId
						});
						this.filterUserOfOtherRole(DOCUMENT_VALIDATION_LEVEL.gateKeeper, dVCS.userId);
						this.selectedGateKeeper = dVCS.userId;
						this.toSignGateKeeper = dVCS.toSign;
					}

					if (dVCS.validationLevel === DOCUMENT_VALIDATION_LEVEL.productionAccountant) {
						this[`step${i}`].productionAccountant = true;
						this.roleByStepToSendOriginal.push({
							docId: +this.document.id,
							step: i,
							validationLevel: DOCUMENT_VALIDATION_LEVEL.productionAccountant,
							toSign: dVCS.toSign,
							userId: dVCS.userId
						});
						this.filterUserOfOtherRole(DOCUMENT_VALIDATION_LEVEL.productionAccountant, dVCS.userId);
						this.selectedProductionAccountant = dVCS.userId;
						this.toSignProdAcc = dVCS.toSign;
					}

					if (dVCS.validationLevel === DOCUMENT_VALIDATION_LEVEL.upm) {
						this[`step${i}`].upm = true;
						this.roleByStepToSendOriginal.push({
							docId: +this.document.id,
							step: i,
							validationLevel: DOCUMENT_VALIDATION_LEVEL.upm,
							toSign: dVCS.toSign,
							userId: dVCS.userId
						});
						this.filterUserOfOtherRole(DOCUMENT_VALIDATION_LEVEL.upm, dVCS.userId);
						this.selectedUpm = dVCS.userId;
						this.toSignUPM = dVCS.toSign;
					}

					if (dVCS.validationLevel === DOCUMENT_VALIDATION_LEVEL.lineProducer) {
						this[`step${i}`].lineProducer = true;
						this.roleByStepToSendOriginal.push({
							docId: +this.document.id,
							step: i,
							validationLevel: DOCUMENT_VALIDATION_LEVEL.lineProducer,
							toSign: dVCS.toSign,
							userId: dVCS.userId
						});
						this.filterUserOfOtherRole(DOCUMENT_VALIDATION_LEVEL.lineProducer, dVCS.userId);
						this.selectedLineProducer = dVCS.userId;
						this.toSignLineProd = dVCS.toSign;
					}

					if (dVCS.validationLevel === DOCUMENT_VALIDATION_LEVEL.studio) {
						this[`step${i}`].studio = true;
						this.roleByStepToSendOriginal.push({
							docId: +this.document.id,
							step: i,
							validationLevel: DOCUMENT_VALIDATION_LEVEL.studio,
							toSign: dVCS.toSign,
							userId: dVCS.userId
						});
						this.filterUserOfOtherRole(DOCUMENT_VALIDATION_LEVEL.studio, dVCS.userId);
						this.selectedStudio = dVCS.userId;
						this.toSignStudio = dVCS.toSign;
					}
				});
			}
		},
		checkDisabledToSign(field) {
			const validationOption = DOCUMENT_VALIDATION_LEVEL[field];

			let findIndexFromRoleToSend = -1;
			let findIndexFromRoleOriginal = -1;

			if (this.roleByStepToSend.length > 0) {
				findIndexFromRoleToSend = _.findIndex(this.roleByStepToSend, (rBSTS) => rBSTS.validationLevel === validationOption);
			}

			if (this.roleByStepToSendOriginal.length > 0) {
				findIndexFromRoleOriginal = _.findIndex(this.roleByStepToSendOriginal, (rBSTSO) => rBSTSO.validationLevel === validationOption);
			}

			return findIndexFromRoleToSend === -1 && findIndexFromRoleOriginal === -1;
		},
		deleteRoleSelectedFromStep(step) {
			const findIndex = this.roleByStepToSend.findIndex((option) => option.step === step && option.action == 1);

			if (findIndex === -1) {
				let roleByStepToSend = this.roleByStepToSend;
				let roleByStepToSendOriginal = this.roleByStepToSendOriginal;
				if (roleByStepToSend.length > 0) {
					_.remove(roleByStepToSend, (el) => {
						return el.step === step;
					});
				}

				if (roleByStepToSendOriginal.length > 0) {
					for (let i = 0; i < roleByStepToSendOriginal.length; i++) {
						const element = roleByStepToSendOriginal[i];
						if (element.step === step) {
							roleByStepToSend.push({
								docId: element.docId,
								step,
								validationLevel: element.validationLevel,
								action: 1,
								toSign: element.toSign
							});
						}
					}
				}

				this[`step${step}`].gateKeeper = false;
				this[`step${step}`].productionAccountant = false;
				this[`step${step}`].upm = false;
				this[`step${step}`].lineProducer = false;
				this[`step${step}`].studio = false;

				this.roleByStepToSend = roleByStepToSend;

				this.$emit('choice-step-document:change', roleByStepToSend);
			}
		}
	}
};
</script>
